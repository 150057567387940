import {useEffect} from 'react'
import {logMessage} from '@/services/client-logger'
import {env} from '@/utils/env/client.mjs'
import {useTrustArcConsent} from '../trustarc'

export function useBazaarVoice(locale: string | undefined): void {
	const cookieValue = useTrustArcConsent()
	useEffect(() => {
		if (env.NEXT_PUBLIC_TOWER === 'pet' && locale === 'us') {
			const shouldTrack =
				cookieValue === 'ALL_COOKIES' ||
				cookieValue === 'ADVERTISE_COOKIES' // need to enable tracking mode for these types of cookies
			if (window.BV) {
				logMessage(`Bazaar voice tracking is enabled: ${shouldTrack}`)
				window.BV.cookieConsent.setConsent({
					BVBRANDID: shouldTrack,
					bv_metrics: shouldTrack,
					BVBRANDSID: shouldTrack,
				})
			}
		}
	}, [cookieValue, locale])
}
