export const getDataAttributes = (
	props: Record<string, unknown>
): Record<string, unknown> => {
	return Object.keys(props)
		.filter((key) => key.startsWith('data-'))
		.reduce<Record<string, unknown>>((obj, key) => {
			const newObj = obj
			newObj[key] = props[key]
			return newObj
		}, {})
}
