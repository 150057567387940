/* eslint-disable @typescript-eslint/no-extraneous-class -- need class in this way */
import {env} from '@/utils/env/client.mjs'
import {ApplicationInsights} from '@microsoft/applicationinsights-web'
class ClientLoggerFactory {
	static instance: ApplicationInsights
	private constructor() {
		throw new Error('Use ClientLoggerFactory.getInstance()')
	}
	static getInstance(): ApplicationInsights {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- it is the correct condition
		if (!ClientLoggerFactory.instance) {
			const connectionString =
				env.NEXT_PUBLIC_APPINSIGHTS_CONNECTION_STRING
			if (!connectionString) {
				throw new Error(
					'Application Insights connection string is not defined.'
				)
			}
			ClientLoggerFactory.instance = new ApplicationInsights({
				config: {
					connectionString,
					enableAutoRouteTracking: true,
					enableDebug: true, // Enable debug logs
					loggingLevelConsole: 2,
				},
			})
			ClientLoggerFactory.instance.loadAppInsights()
		}
		return ClientLoggerFactory.instance
	}
	static enableTelemetry(): void {
		const instance = ClientLoggerFactory.getInstance()
		instance.config.disableTelemetry = false
	}
	static disableTelemetry(): void {
		const instance = ClientLoggerFactory.getInstance()
		instance.config.disableTelemetry = true
	}
	static isTelemetryEnabled(): boolean {
		const instance = ClientLoggerFactory.getInstance()
		return !instance.config.disableTelemetry // If disableTelemetry is false, telemetry is enabled
	}
}
function trackPageView(): void {
	ClientLoggerFactory.getInstance().trackPageView()
}
function disablePageView(): void {
	ClientLoggerFactory.disableTelemetry()
}
// Function to enable page view tracking (when the user accepts the trust banner)
function enablePageView(): void {
	ClientLoggerFactory.enableTelemetry()
	trackPageView() // Optionally track the current page view after enabling
}

function logError(exception: unknown): void {
	console.error(exception)
	if (ClientLoggerFactory.isTelemetryEnabled()) {
		if (exception instanceof Error) {
			ClientLoggerFactory.getInstance().trackException({exception})
		} else if (typeof exception === 'object') {
			ClientLoggerFactory.getInstance().trackException({
				exception: new Error(JSON.stringify(exception)),
			})
		} else {
			ClientLoggerFactory.getInstance().trackException({
				exception: new Error(String(exception)),
			})
		}
	}
}
function logMessage(message: string): void {
	if (ClientLoggerFactory.isTelemetryEnabled()) {
		ClientLoggerFactory.getInstance().trackTrace({message})
	}
}
function logEvent(event: {name: string; properties: object}): void {
	if (ClientLoggerFactory.isTelemetryEnabled()) {
		ClientLoggerFactory.getInstance().trackEvent({
			name: event.name,
			properties: event.properties,
		})
	}
}
export {enablePageView, disablePageView, logMessage, logEvent, logError}
