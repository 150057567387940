import {useState, useEffect} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {Button as CButton} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'

export type SiteBannerSelfValidationContentItem = IContentItem<{
	title: Elements.TextElement
	content: Elements.RichTextElement
	buttons: Elements.LinkedItemsElement<CtaButtonContentItem>
	popupSuppress: Elements.NumberElement
	selfValidationBannerTextalignment: Elements.MultipleChoiceElement
}>

export const SiteBannerSelfValidationBlock: Block<
	SiteBannerSelfValidationContentItem
> = ({
	block: {
		elements: {
			title,
			content,
			buttons,
			popupSuppress,
			selfValidationBannerTextalignment: alignment,
		},
	},
	...context
}) => {
	const cookieName = 'bannerCookie'
	const [showBanner, setShowBanner] = useState(true)
	const [currentUrlWithHash, setCurrentUrlWithHash] = useState('')

	function setCookie(name: string, value: string, days: number): void {
		const cookieExpiryMilliseconds = days * 24 * 60 * 60 * 1000
		const date = new Date()
		date.setTime(date.getTime() + cookieExpiryMilliseconds)
		document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`
	}

	// Store the current URL (including anchor) before showing the banner
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const currentUrl = window.location.href
			setCurrentUrlWithHash(currentUrl)
		}
	}, [])

	const handleCtaButtons = (choice: string, cookieExpiry: number): void => {
		if (!choice) {
			document.body.style.overflow = 'visible'
			setShowBanner(false)
			setCookie(cookieName, 'true', cookieExpiry)
			// After closing the banner, restore the URL with anchor (if saved)
			if (currentUrlWithHash) {
				window.location.href = currentUrlWithHash
			}
		} else {
			document.body.style.overflow = 'hidden'
			setShowBanner(true)
		}
	}

	return (
		<div
			className={clsx(
				`fixed inset-y-0 z-[999] w-full backdrop-blur-[5px] backdrop-filter ${
					!showBanner ? 'hidden' : ''
				}`
			)}
		>
			<div className="m-auto h-screen bg-[rgba(28,28,28,0.76)] px-4 pb-4 pt-20">
				<div
					className="mx-auto my-0 w-full max-w-2xl"
					style={{
						// thick needed to mask pointy corners that poke out on chrome!
						border: 'thick solid #F8F8F8',
						borderRadius: '10px',
						minWidth: '350px',
					}}
				>
					<div className="bg-[#F8F8F8] pb-5 pt-[30px]">
						<div
							className={clsx(
								alignment[0]?.codename === 'left'
									? 'pl-5 pr-5 text-left text-[#373737]'
									: 'pl-5 pr-5 text-center text-[#373737]'
							)}
						>
							<p className="text-2xl font-extrabold leading-[1.18]">
								{title}
							</p>
						</div>
					</div>
					<div className="border-t-[1px] border-[lightgrey] bg-[#F8F8F8] pb-[15px] pt-[15px]">
						<RichTextRenderer
							className={clsx(
								alignment[0]?.codename === 'left'
									? 'justify-left pl-5 pr-5 text-left'
									: 'justify-left pl-5 pr-5 text-center text-[#373737]'
							)}
							data-kontent-element-codename="content"
							element={content}
							{...context}
						/>
					</div>
					<div className="mx-auto my-0 flex w-full justify-center bg-[#F8F8F8] pb-[30px]">
						{buttons.map(
							({elements: {url, text}, system: {id}}) => {
								// Append hash to the URL only if it's available
								const updatedUrl = currentUrlWithHash
									? `${url}${window.location.hash}`
									: url

								return (
									<CButton
										className="ml-2 mr-2 w-1/4"
										href={updatedUrl}
										key={id}
										onClick={() => {
											handleCtaButtons(
												url,
												popupSuppress ?? 0
											)
										}}
									>
										{text}
									</CButton>
								)
							}
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
